import axios, { AxiosResponse } from "axios";
import { Gallery } from "../../types/Gallery";

type Result = {
  data: Gallery[];
  paging: {
    cursors: {
      after: string;
      before: string;
    };
    next: string;
  };
};

export const getGallery = async (limit?: number) => {
  const limit_ = limit || 20;
  const url = `https://graph.instagram.com/me/media?fields=id,caption,children,media_url,timestamp,media_type,permalink&limit=${limit_}&access_token=${process.env.REACT_APP_INSTA_KEY}`;

  try {
    const instaPosts = await axios.get<any, AxiosResponse<Result, any>>(url);

    return instaPosts.data;
  } catch (error) {
    console.log("Error fetching insta posts", error);
  }
};
